import React from 'react'
import {
  Control,
  Controller,
  FieldErrors,
  FieldValues,
  UseFormRegister,
  UseFormReturn,
} from 'react-hook-form'

import { AppCheckBox } from 'components/Common/AppFormFields/AppCheckBox/AppCheckBox.component'
import { AppTextField } from 'components/Common/AppFormFields/AppTextField/AppTextField.component'

interface IAppEditBusinessSocialMediaFields {
  register: UseFormRegister<FieldValues>
  control: Control<FieldValues>
  errors: FieldErrors<FieldValues>
  setValue: UseFormReturn['setValue']
  watch: (name: string) => FieldValues[keyof FieldValues]
  clearErrors: (name?: keyof FieldValues) => void
}
export const AppEditBusinessSocialMediaFields: React.FC<IAppEditBusinessSocialMediaFields> = ({
  errors,
  watch,
  control,
  setValue,
  clearErrors,
}) => {
  const hasFacebook = watch('hasFacebook')
  const hasInstagram = watch('hasInstagram')
  const hasLinkedin = watch('hasLinkedin')
  const hasTikTok = watch('hasTikTok')
  const hasTwitter = watch('hasTwitter')
  const hasYoutube = watch('hasYoutube')

  return (
    <>
      <div>Business Social Media</div>
      <div className="w-full">
        <Controller
          name={'facebook'}
          control={control}
          render={({ field }) => (
            <AppTextField
              size="md"
              {...field}
              label={`Facebook${hasFacebook === false ? '' : ' *'}`}
              placeholder={`handle`}
              prefix="www.facebook.com/"
              error={(errors.facebook?.message as string) || ''}
              disabled={hasFacebook === false}
            />
          )}
        />

        <div className="pl-1 mt-2">
          <Controller
            name="hasFacebook"
            control={control}
            defaultValue={true}
            render={({ field }) => (
              <AppCheckBox
                id="hasFacebook"
                label={'I don’t have one'}
                value={field.value === false}
                onChange={(e) => {
                  const isChecked = e.target.checked
                  field.onChange(!isChecked)
                  setValue('facebook', '')
                  clearErrors('facebook')
                }}
              />
            )}
          />
        </div>
        <div className="w-full">
          <Controller
            name="instagram"
            control={control}
            render={({ field }) => (
              <AppTextField
                size="md"
                type="text"
                label={`Instagram${hasInstagram === false ? '' : ' *'}`}
                prefix="www.instagram.com/"
                placeholder="handle"
                error={errors.instagram?.message as string}
                {...field}
                disabled={hasInstagram === false}
              />
            )}
          />
          <div className="pl-1 mt-2">
            <Controller
              name="hasInstagram"
              control={control}
              render={({ field }) => (
                <AppCheckBox
                  id="hasInstagram"
                  label={'I don’t have one'}
                  value={field.value === false}
                  onChange={(e) => {
                    const isChecked = e.target.checked
                    const newValue = !isChecked
                    field.onChange(newValue)
                    setValue('instagram', '')
                    clearErrors('instagram')
                  }}
                />
              )}
            />
          </div>
        </div>
        <div className="w-full ">
          <Controller
            name="linkedin"
            control={control}
            render={({ field }) => (
              <AppTextField
                size="md"
                type="text"
                placeholder="handle"
                prefix="www.linkedin.com/in/"
                label={`Linkedin${hasLinkedin === false ? '' : ' *'}`}
                error={errors.linkedin?.message as string}
                {...field}
                disabled={hasLinkedin === false}
              />
            )}
          />
          <div className="pl-1 mt-2">
            <Controller
              name="hasLinkedin"
              control={control}
              defaultValue={true}
              render={({ field }) => (
                <AppCheckBox
                  id="hasLinkedin"
                  label={'I don’t have one'}
                  value={field.value === false}
                  onChange={(e) => {
                    const isChecked = e.target.checked
                    const newValue = !isChecked
                    field.onChange(newValue)
                    setValue('linkedin', '')
                    clearErrors('linkedin')
                  }}
                />
              )}
            />
          </div>
        </div>
      </div>
      <div className="w-full ">
        <Controller
          name={'tiktok'}
          control={control}
          render={({ field }) => (
            <AppTextField
              size="md"
              {...field}
              label={`TikTok${hasTikTok === false ? '' : ' *'}`}
              placeholder={`@handle`}
              prefix="www.tiktok.com/"
              error={(errors.tiktok?.message as string) || ''}
              disabled={hasTikTok === false}
            />
          )}
        />

        <div className="pl-1 mt-2">
          <Controller
            name="hasTikTok"
            control={control}
            defaultValue={true}
            render={({ field }) => (
              <AppCheckBox
                id="hasTikTok"
                label={'I don’t have one'}
                value={field.value === false}
                onChange={(e) => {
                  const isChecked = e.target.checked
                  const newValue = !isChecked
                  field.onChange(newValue)
                  setValue('tiktok', '')
                  clearErrors('tiktok')
                }}
              />
            )}
          />
        </div>
        <div className="w-full ">
          <Controller
            name="twitter"
            control={control}
            render={({ field }) => (
              <AppTextField
                size="md"
                type="text"
                label={`X (Twitter)${hasTwitter === false ? '' : ' *'}`}
                placeholder="handle"
                prefix="www.x.com/"
                error={errors.twitter?.message as string}
                {...field}
                disabled={hasTwitter === false}
              />
            )}
          />
          <div className="pl-1 mt-2">
            <Controller
              name="hasTwitter"
              control={control}
              defaultValue={true}
              render={({ field }) => (
                <AppCheckBox
                  id="hasTwitter"
                  label={'I don’t have one'}
                  value={field.value === false}
                  onChange={(e) => {
                    const isChecked = e.target.checked
                    const newValue = !isChecked
                    field.onChange(newValue)
                    setValue('twitter', '')
                    clearErrors('twitter')
                  }}
                />
              )}
            />
          </div>
        </div>
        <div className="w-full ">
          <Controller
            name="youtube"
            control={control}
            render={({ field }) => (
              <AppTextField
                size="md"
                type="text"
                placeholder="@handle"
                prefix="www.youtube.com/"
                label={`YouTube${hasYoutube === false ? '' : ' *'}`}
                error={errors.youtube?.message as string}
                {...field}
                disabled={hasYoutube === false}
              />
            )}
          />
          <div className="pl-1 mt-2">
            <Controller
              name="hasYoutube"
              control={control}
              defaultValue={true}
              render={({ field }) => (
                <AppCheckBox
                  id="hasYoutube"
                  label={'I don’t have one'}
                  value={field.value === false}
                  onChange={(e) => {
                    const isChecked = e.target.checked
                    const newValue = !isChecked
                    field.onChange(newValue)
                    setValue('youtube', '')
                    clearErrors('youtube')
                  }}
                />
              )}
            />
          </div>
        </div>
      </div>
    </>
  )
}
