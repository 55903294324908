import styled from 'styled-components'
import tw from 'twin.macro'

export const AppFieldWrapper = styled.div`
  ${tw`mt-6`}
`

export const AppLabelWrapper = styled.p`
  ${tw`text-black-light mb-2 xs:text-base sm:text-xl font-primary font-normal     
    flex
    gap-2
    items-center
    w-full
    text-app-body-regular-font-size 
    font-app-body-regular-font-family 
    font-app-body-regular-font-weight
    leading-app-body-small-line-height
    tracking-app-body-regular-letter-spacing`}
`

export const AppValueWrapper = styled.p`
  ${tw`text-black-light xs:text-base sm:text-xl font-primary font-bold`}
`

export const AppSelectFieldWrapper = styled.p<{ applyStyling?: boolean }>(({ applyStyling }) => {
  return [applyStyling ? tw`xs:col-span-12 sm:col-span-6 md:col-span-6 mdl:col-span-6 mt-3` : tw``]
})

export const AppParagraphWrapper = styled.p`
  ${tw`text-black-appDime text-sm mt-4`}
`
