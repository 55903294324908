import React from 'react'

export const AppYouTubeIcon: React.ComponentType = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      className="rounded-lg"
    >
      <path d="M18.6 16L14.4 13.6V18.4L18.6 16Z" fill="#705AF8" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0 0H32V32H0V0ZM22.2 10.7C22.9 10.9 23.4 11.4 23.6 12.1C24 13.4 24 16 24 16C24 16 24 18.6 23.7 19.9C23.5 20.6 23 21.1 22.3 21.3C21 21.6 16 21.6 16 21.6C16 21.6 10.9 21.6 9.7 21.3C9 21.1 8.5 20.6 8.3 19.9C8 18.6 8 16 8 16C8 16 8 13.4 8.2 12.1C8.4 11.4 8.90001 10.9 9.60001 10.7C10.9 10.4 15.9 10.4 15.9 10.4C15.9 10.4 21 10.4 22.2 10.7Z"
        fill="#705AF8"
      />
    </svg>
  )
}
