import React, { useMemo } from 'react'

import { LABEL, TOOLTIP_TEXT } from 'config'
import { BusinessProfile } from '__generated__/api-types-and-hooks'
import { calculateYearMonths, formatDateIntoEnglish, getMonthAndYear } from 'utils/helper'
import { AppLabelWrapper } from 'components/AppAccount/AppAccount.style'
import {
  appBusinessStage,
  appWhereDoYouDoBusinessIds,
  registeredBusinessStructureV2,
} from 'components/MonthlyAttestation/utils'
import { AppExpandItemsAccordion } from 'components/Common/AppExpandItemsAccordian/AppExpandItemsAccordion.component'
import { AppPillTextBoxList } from 'components/AppAccount/AppBusinessProfile/AppPillTextBoxList/AppPillTextBoxList.component'
import { AppBusinessProfileCheckBoxField } from 'components/AppAccount/AppBusinessProfile/AppBusinessProfileCheckBoxField/AppBusinessProfileCheckBoxField.component'
import { AppFacebookIcon } from 'components/Common/AppSvgIcons/AppFacebookIcon'
import { AppInstagramIcon } from 'components/Common/AppSvgIcons/AppInstagramIcon'
import { AppLinkedinIcon } from 'components/Common/AppSvgIcons/AppLinkedinIcon'
import { AppTikTokIcon } from 'components/Common/AppSvgIcons/AppTikTokIcon'
import { AppTwitterIcon } from 'components/Common/AppSvgIcons/AppTwitterIcon'
import { AppYouTubeIcon } from 'components/Common/AppSvgIcons/AppYouTubeIcon'
import { AppViewBusinessProfileField } from 'components/AppAccount/AppBusinessProfile/AppBusinessProfileField/AppBusinessProfileField.component'

interface IAppViewBusinessProfile {
  businessProfile: BusinessProfile
}

export const AppViewBusinessProfile: React.FC<IAppViewBusinessProfile> = ({ businessProfile }) => {
  // is there other option in whereDoYouDoBusiness
  const isHaveOtherWhereDoYouDoBusiness = useMemo(() => {
    const whereDoYouDoBusinessOptionIds = businessProfile?.whereDoYouDoBusinessOptionIds || []
    if (whereDoYouDoBusinessOptionIds.some((id) => id === appWhereDoYouDoBusinessIds.other)) {
      return true
    }
    return false
  }, [businessProfile])

  return (
    <>
      <AppViewBusinessProfileField
        toolTipText={TOOLTIP_TEXT.BUSINESS_NAME}
        label={LABEL.BUSINESS_NAME}
        value={businessProfile?.businessName}
      />
      <AppViewBusinessProfileField
        toolTipText={TOOLTIP_TEXT.REGISTER_BUSINESS_NAME}
        label={LABEL.DBA}
        value={businessProfile?.dba}
      />
      {businessProfile?.businessAddress?.hasBusinessAddress !== false ? (
        <AppViewBusinessProfileField
          toolTipText={TOOLTIP_TEXT.REGISTER_BUSINESS_ADDRESS}
          label={LABEL.REGISTER_BUSINESS_ADDRESS}
          value={businessProfile?.businessAddress?.addressLine1}
        />
      ) : (
        <AppBusinessProfileCheckBoxField
          label={LABEL.REGISTER_BUSINESS_ADDRESS}
          checkboxLabel={`I don’t have one`}
          checkboxValue={businessProfile?.businessAddress?.hasBusinessAddress === false}
        />
      )}
      <AppViewBusinessProfileField
        toolTipText={TOOLTIP_TEXT.BUSINESS_EMAIL}
        label={LABEL.BUSINESS_EMAIL}
        value={businessProfile?.businessEmail}
      />
      {businessProfile?.hasWebsite !== false ? (
        <AppViewBusinessProfileField
          toolTipText={TOOLTIP_TEXT.WEBSITE}
          label={LABEL.BUSINESS_WEBSITE}
          value={businessProfile?.domainName}
        />
      ) : (
        <AppBusinessProfileCheckBoxField
          label={LABEL.BUSINESS_WEBSITE}
          checkboxLabel={`I don’t have one`}
          checkboxValue={businessProfile?.hasWebsite === false}
        />
      )}
      <AppExpandItemsAccordion title="Business Social Media" open={false} onOpen={() => {}}>
        {businessProfile?.hasFacebook !== false ? (
          <AppViewBusinessProfileField
            LabelIcon={AppFacebookIcon}
            value={businessProfile?.facebook}
          />
        ) : (
          <AppBusinessProfileCheckBoxField
            LabelIcon={AppFacebookIcon}
            checkboxLabel={`I don’t have one`}
            checkboxValue={businessProfile?.hasFacebook === false}
          />
        )}
        {businessProfile?.hasInstagram !== false ? (
          <AppViewBusinessProfileField
            LabelIcon={AppInstagramIcon}
            value={businessProfile?.instagram}
          />
        ) : (
          <AppBusinessProfileCheckBoxField
            LabelIcon={AppInstagramIcon}
            checkboxLabel={`I don’t have one`}
            checkboxValue={businessProfile?.hasInstagram === false}
          />
        )}
        {businessProfile?.hasLinkedin !== false ? (
          <AppViewBusinessProfileField
            LabelIcon={AppLinkedinIcon}
            value={businessProfile?.linkedin}
          />
        ) : (
          <AppBusinessProfileCheckBoxField
            LabelIcon={AppLinkedinIcon}
            checkboxLabel={`I don’t have one`}
            checkboxValue={businessProfile?.hasLinkedin === false}
          />
        )}
        {businessProfile?.hasTikTok !== false ? (
          <AppViewBusinessProfileField LabelIcon={AppTikTokIcon} value={businessProfile?.tiktok} />
        ) : (
          <AppBusinessProfileCheckBoxField
            LabelIcon={AppTikTokIcon}
            checkboxLabel={`I don’t have one`}
            checkboxValue={businessProfile?.hasTikTok === false}
          />
        )}
        {businessProfile?.hasTwitter !== false ? (
          <AppViewBusinessProfileField
            LabelIcon={AppTwitterIcon}
            value={businessProfile?.twitter}
          />
        ) : (
          <AppBusinessProfileCheckBoxField
            LabelIcon={AppTwitterIcon}
            checkboxLabel={`I don’t have one`}
            checkboxValue={businessProfile?.hasTwitter === false}
          />
        )}
        {businessProfile?.hasYoutube !== false ? (
          <AppViewBusinessProfileField
            LabelIcon={AppYouTubeIcon}
            value={businessProfile?.youtube}
          />
        ) : (
          <AppBusinessProfileCheckBoxField
            LabelIcon={AppYouTubeIcon}
            checkboxLabel={`I don’t have one`}
            checkboxValue={businessProfile?.hasYoutube === false}
          />
        )}
      </AppExpandItemsAccordion>
      <AppLabelWrapper>
        {businessProfile.businessStageOptionId === appBusinessStage.launched
          ? LABEL.WHERE_DO_YOU_DO_BUSINEESS
          : LABEL.WHERE_DO_YOU_PLAN_TO_DO_BUSINEESS}
      </AppLabelWrapper>
      <div className="flex flex-wrap gap-1 !mt-1">
        <AppPillTextBoxList items={businessProfile?.whereDoYouDoBusiness || []} />
      </div>
      {isHaveOtherWhereDoYouDoBusiness && (
        <AppViewBusinessProfileField
          label={LABEL.OTHER_WHERE_DO_YOU_DO_BUSINEESS}
          value={businessProfile?.otherWhereDoYouDoBusiness}
        />
      )}
      {businessProfile?.hasRegisterBusinessStructure !== false ? (
        <AppViewBusinessProfileField
          toolTipText={TOOLTIP_TEXT.REGISTER_BUSINESS_STRUCTURE}
          label={LABEL.REGISTER_BUSINESS_STRUCTURE}
          value={businessProfile?.registerBusinessStructure}
        />
      ) : (
        <AppBusinessProfileCheckBoxField
          label={LABEL.REGISTER_BUSINESS_STRUCTURE}
          checkboxLabel={`I don’t have one`}
          checkboxValue={businessProfile?.hasRegisterBusinessStructure === false}
        />
      )}
      {businessProfile?.hasEIN !== false ? (
        <AppViewBusinessProfileField
          toolTipText={TOOLTIP_TEXT.EIN}
          label={LABEL.EIN}
          value={businessProfile?.EIN}
        />
      ) : (
        <AppBusinessProfileCheckBoxField
          label={LABEL.EIN}
          checkboxLabel={`I don’t have one`}
          checkboxValue={businessProfile?.hasEIN === false}
        />
      )}
      <AppViewBusinessProfileField
        label={LABEL.DATE_ESTABLISHED}
        value={
          businessProfile?.registerBusinessStructureOptionId ===
          registeredBusinessStructureV2.noBusinessOptionId
            ? 'Not yet'
            : businessProfile?.yearEstablished
        }
      />
      <AppViewBusinessProfileField
        label={LABEL.YEARS_IN_BUSINESS}
        value={
          businessProfile?.registerBusinessStructureOptionId ===
          registeredBusinessStructureV2.noBusinessOptionId
            ? '0'
            : calculateYearMonths(
                getMonthAndYear(businessProfile?.yearEstablished).month,
                getMonthAndYear(businessProfile?.yearEstablished).year
              )
        }
      />
      <AppViewBusinessProfileField
        label={LABEL.BUSINESS_STAGE}
        value={businessProfile?.businessStage}
      />
      <AppViewBusinessProfileField
        label={LABEL.REVENUE_STATUS}
        value={businessProfile?.revenueStatus}
      />
      <AppViewBusinessProfileField
        label={LABEL.FULL_TIME_EMPLOYEES}
        value={businessProfile?.fullTimeEmployees}
      />
      <AppViewBusinessProfileField
        label={LABEL.PART_TIME_EMPLOYEES}
        value={businessProfile?.partTimeEmployees}
      />
      <AppViewBusinessProfileField
        label={LABEL.CONTRACT_EMPLOYESS}
        value={businessProfile?.contractors}
      />
      <AppViewBusinessProfileField label={LABEL.VOLUNTEERS} value={businessProfile?.volunteers} />
      <AppViewBusinessProfileField
        label={LABEL.MINORITY_OWNED}
        value={businessProfile?.minorityOwned}
      />
      <AppViewBusinessProfileField
        label={LABEL.SBA_CERTIFIED}
        value={businessProfile?.sbaCertified}
      />
      <AppViewBusinessProfileField
        label={LABEL.VETERA_OWNED}
        value={businessProfile?.veteranOwned}
      />
      <AppViewBusinessProfileField label={LABEL.HOURS_SPENT} value={businessProfile?.hoursSpent} />
      <AppViewBusinessProfileField
        label={LABEL.HOURS_SPENT_PER_WEEK}
        value={businessProfile?.hoursSpentPerWeek}
      />
      <AppLabelWrapper>
        {businessProfile.businessStageOptionId === appBusinessStage.launched
          ? LABEL.PRODUCT_AND_SERVICES_LAUNCH
          : LABEL.PRODUCT_AND_SERVICES_PRE_LAUNCH}
      </AppLabelWrapper>
      <div className="flex flex-wrap gap-1 !mt-1">
        <AppPillTextBoxList items={businessProfile?.productsAndServices ?? []} />
      </div>
      <AppViewBusinessProfileField label={LABEL.EXIT_PLAN} value={businessProfile?.exitPlan} />
      <AppViewBusinessProfileField
        label={LABEL.OTHER_EXIT_PLAN}
        value={businessProfile?.otherExitPlan}
      />
      <AppViewBusinessProfileField label={LABEL.MOTIVATION} value={businessProfile?.motivation} />
      <AppViewBusinessProfileField
        label={LABEL.BUSINESS_VISION}
        value={businessProfile?.businessVision}
      />
      <AppViewBusinessProfileField
        label={LABEL.PERSONAL_GOALS}
        value={businessProfile?.personalGoals}
      />
      <AppViewBusinessProfileField
        label={LABEL.LAUNCH_DATE}
        value={formatDateIntoEnglish(businessProfile?.launchDate ?? '')}
      />
      <AppViewBusinessProfileField
        label={LABEL.BUSINESS_DESCRIPTION}
        value={businessProfile?.businessDescription}
      />
      <AppViewBusinessProfileField
        label={LABEL.LAST_YEAR_REVENUE}
        value={businessProfile?.lastYearRevenue}
      />
      <AppViewBusinessProfileField
        label={LABEL.THIS_YEAR_REVENUE}
        value={businessProfile?.thisYearRevenue}
      />
      <AppViewBusinessProfileField
        label={LABEL.GROSS_REVENUE_GOAL_FOR_THIS_YEAR}
        value={businessProfile?.grossRevenueGoalForThisYear}
      />
      <AppViewBusinessProfileField
        label={LABEL.HAVE_YOU_START_GENERATING_SALES}
        value={
          businessProfile?.haveStartedGeneratingSales === true
            ? 'Yes'
            : businessProfile?.haveStartedGeneratingSales === false
            ? 'No'
            : ''
        }
      />
      <AppViewBusinessProfileField
        label={LABEL.LAST_UPDATED_BUSINESS_PLAN}
        value={formatDateIntoEnglish(businessProfile?.lastUpdatedBusinessPlan ?? '')}
      />

      <div className="border-primary-outline border-t-[1px] mt-4" />
      <h1 className="text-xl font-bold my-7">Assessment Info</h1>
      <AppViewBusinessProfileField label={LABEL.NAICS_CODE} value={businessProfile?.naicsCode} />
    </>
  )
}
