import React from 'react'
import {
  Control,
  Controller,
  FieldErrors,
  FieldValues,
  UseFormRegister,
  UseFormReturn,
} from 'react-hook-form'

import { AppCheckBox } from 'components/Common/AppFormFields/AppCheckBox/AppCheckBox.component'
import { AppTextField } from 'components/Common/AppFormFields/AppTextField/AppTextField.component'
import { TOOLTIP_TEXT } from 'config'

interface IAppEditBusinessSocialMediaFields {
  register: UseFormRegister<FieldValues>
  control: Control<FieldValues>
  errors: FieldErrors<FieldValues>
  setValue: UseFormReturn['setValue']
  watch: (name: string) => FieldValues[keyof FieldValues]
  clearErrors: (name?: keyof FieldValues) => void
}
export const AppEditBusinessDomainFields: React.FC<IAppEditBusinessSocialMediaFields> = ({
  errors,
  watch,
  control,
  setValue,
  clearErrors,
}) => {
  const hasWebsite = watch('hasWebsite')
  return (
    <>
      <div className="mt-4 mb-6">
        <Controller
          name="domainName"
          control={control}
          render={({ field }) => (
            <AppTextField
              toolTipText={TOOLTIP_TEXT.WEBSITE}
              size="md"
              type="text"
              label={`Website${hasWebsite ? ' *' : ''}`}
              error={errors.domainName?.message as string}
              {...field}
              placeholder="www.mywebsite.com"
              prefix="https://"
              disabled={hasWebsite === false}
            />
          )}
        />
        <div className="pl-1 mt-2">
          <Controller
            name="hasWebsite"
            control={control}
            defaultValue={true}
            render={({ field }) => (
              <AppCheckBox
                id="hasWebsite"
                label={'I don’t have a website'}
                value={field.value === false}
                onChange={(e) => {
                  const isChecked = e.target.checked
                  const newValue = !isChecked
                  field.onChange(newValue)
                  setValue('domainName', '')
                  clearErrors('domainName')
                }}
              />
            )}
          />
        </div>
      </div>
    </>
  )
}
