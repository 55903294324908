import ValidationSchemaBuilder from 'appUtils/AppValidationSchemaBuilder'

const schemaBuilder = new ValidationSchemaBuilder()

export const profileConfirmation = schemaBuilder
  .firstName()
  .lastName()
  .email()
  .mobileContactNumber()
  .isSmsEnabled()
  .businessName()
  .registeredBusinessStructure()
  .otherRegisteredBusinessStructure()
  .businessEINCode()
  .domainName()
  .hasAvatar()
  .hasWebsite()
  .imagaData()
  .businessAddress()
  .logo()
  .hasEIN()
  .businessEmail()
  .avatar()
  .yearEstablished()
  .hasRegisterBusinessStructure()
  .dba()
  .facebook()
  .instagram()
  .linkedin()
  .tiktok()
  .twitter()
  .youtube()
  .hasFacebook()
  .hasTikTok()
  .hasLinkedin()
  .hasInstagram()
  .hasYoutube()
  .hasTwitter()
  .getSchema()

export const userTaskForm = schemaBuilder
  .name()
  .assigneeId()
  .estimatedEffortInMinutes()
  .order()
  .taskPriority()
  .status()
  .category()
  .instructionForTask()
  .expectedEndDate()
  .startDate()
  .milestoneId()
  .howToLinks()
  .getSchema()

export const intakeForm = schemaBuilder.businessName().businessEINCode().city().state().getSchema()

export const AccountBusinessSchema = schemaBuilder
  .businessEINCode()
  .naicsCode()
  .businessName()
  .businessEmail()
  .businessWebsite()
  .businessAddressUpdate()
  .clientDetailsVolunteers()
  .clientDetailsContractors()
  .clientDetailsFullTimeEmployees()
  .clientDetailsPartTimeEmployees()
  .totalEmployeesUpdate()
  .yearEstablishedUpdate()
  .otherRegisteredBusinessStructure()
  .otherExitPlan()
  .launchDate()
  .lastUpdatedBusinessPlan()
  .facebook()
  .instagram()
  .linkedin()
  .twitter()
  .tiktok()
  .yearEstablished()
  .youtube()
  .businessAddress()
  .hasRegisterBusinessStructure()
  .hasEIN()
  .hasWebsite()
  .hasFacebook()
  .hasTikTok()
  .hasLinkedin()
  .hasInstagram()
  .hasYoutube()
  .hasTwitter()
  .hoursSpentPerWeek()
  .domainName()
  .getSchema()
