import React, { memo } from 'react'

import find from 'lodash/find'
import { AppFC } from 'types'

import Select from 'react-select'

import { AppErrorMessage } from 'components/Common/AppFormFields/AppTextField/AppTextField.component'
import { AppChevronDownIcon } from 'components/Common/AppSvgIcons/AppChevronDownIcon'
import appColors from 'appColors'
import {
  AppFieldParentWrapper,
  AppFieldWrapper,
  AppLabelWrapper,
} from '../AppCommonStyles/AppFormField.style'
import { AppToolTip } from 'components/Common/AppToolTip/AppToolTip.component'
import { AppToolTipIcon } from 'components/Common/AppToolTip/AppToolTipIcon'

export interface IAppSelectFieldProps {
  size: 'lg' | 'md' | 'sm'
  label?: string
  errors?: string
  value?: string
  disabled?: boolean
  placeholder?: string
  onChange?: (value) => void
  onBlur?: (value) => void
  options: Array<{ label: string; value: string }>
  className?: string
  toolTipText?: string
}

export const AppSelectField: AppFC<IAppSelectFieldProps> = memo(
  ({
    size,
    errors = '',
    label,
    value,
    options,
    onChange,
    disabled,
    placeholder,
    onBlur,
    className,
    toolTipText,
  }) => {
    const getValue = () => {
      return find(options, { value: value }) || null
    }

    return (
      <>
        <div className="w-full">
          {label && (
            <AppLabelWrapper>
              {label}
              {toolTipText && (
                <AppToolTip
                  name={'TextFieldToolTip'}
                  text={toolTipText}
                  variant="primary"
                  place="bottom"
                >
                  <AppToolTipIcon />
                </AppToolTip>
              )}{' '}
            </AppLabelWrapper>
          )}
          <AppFieldParentWrapper>
            <AppFieldWrapper
              size={size}
              error={!!errors}
              disabled={!!disabled}
              className={className}
            >
              <Select
                classNames={{
                  container: () => `!w-full`,
                  valueContainer: () => `!p-0 !h-5`,
                  menu: () => `!mt-4 !w-[106%] !ml-[-16px]`,
                  input: () => `
                    !p-0
                    !m-0
                    !text-app-black-100
                    !text-app-body-regular-font-size
                    !font-app-body-regular-font-family
                    !font-app-body-regular-font-weight
                    !leading-app-body-regular-line-height
                    !tracking-app-body-regular-letter-spacing`,
                  control: () => `
                    !max-h-[20px]
                    !min-h-[20px]
                    !border-none
                    !shadow-none
                    !hover:border-none
                    !hover:shadow-none
                    !bg-transparent
                    ${disabled && '!bg-app-grey-20'}`,
                  singleValue: () => `
                    !text-app-black-100
                    !text-app-body-regular-font-size
                    !font-app-body-regular-font-family
                    !font-app-body-regular-font-weight
                    !leading-app-body-regular-line-height
                    !tracking-app-body-regular-letter-spacing`,
                  placeholder: () => `
                    !text-app-grey-50    
                    !text-app-body-regular-font-size 
                    !font-app-body-regular-font-family 
                    !font-app-body-regular-font-weight
                    !leading-app-body-regular-line-height
                    !tracking-app-body-regular-letter-spacing`,
                }}
                options={options}
                isDisabled={disabled}
                onChange={onChange}
                onBlur={onBlur}
                value={getValue()}
                placeholder={placeholder || 'Placeholder'}
                components={{
                  IndicatorSeparator: () => null,
                  IndicatorsContainer: () => (
                    <AppChevronDownIcon
                      color={disabled ? appColors['app-grey']['50'] : appColors['app-grey']['70']}
                    />
                  ),
                }}
              />
            </AppFieldWrapper>
            {errors && <AppErrorMessage error={errors} />}
          </AppFieldParentWrapper>
        </div>
      </>
    )
  }
)
