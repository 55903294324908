import React from 'react'
import {
  AppFieldWrapper,
  AppLabelWrapper,
  AppValueWrapper,
} from 'components/AppAccount/AppAccount.style'
import { AppFC } from 'types'
import { AppToolTipIcon } from 'components/Common/AppToolTip/AppToolTipIcon'
import { AppToolTip } from 'components/Common/AppToolTip/AppToolTip.component'

interface IAppViewBusinessProfileField {
  LabelIcon?: React.ComponentType
  label?: string
  value?: string | number | null
  toolTipText?: string
}
export const AppViewBusinessProfileField: AppFC<IAppViewBusinessProfileField> = ({
  label,
  value,
  LabelIcon,
  toolTipText,
}) => {
  return (
    <AppFieldWrapper>
      {LabelIcon && <LabelIcon />}
      <AppLabelWrapper>
        {label}
        {toolTipText && (
          <AppToolTip name={'TextFieldToolTip'} text={toolTipText} variant="primary" place="bottom">
            <AppToolTipIcon />
          </AppToolTip>
        )}
      </AppLabelWrapper>
      <AppValueWrapper>{value}</AppValueWrapper>
    </AppFieldWrapper>
  )
}
