import React from 'react'

export const AppToolTipIcon = () => {
  return (
    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.875 9.375L9.90957 9.35771C10.3872 9.11891 10.9249 9.55029 10.7954 10.0683L10.2046 12.4317C10.0751 12.9497 10.6128 13.3811 11.0904 13.1423L11.125 13.125M18 10C18 14.1421 14.6421 17.5 10.5 17.5C6.35786 17.5 3 14.1421 3 10C3 5.85786 6.35786 2.5 10.5 2.5C14.6421 2.5 18 5.85786 18 10ZM10.5 6.875H10.5063V6.88125H10.5V6.875Z"
        stroke="#5E5E5E"
        stroke-width="1.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  )
}
AppToolTipIcon.defaultProps = {
  iconClasses: '',
}
