import React from 'react'
import { AppFC } from 'types'
import {
  AppFieldWrapper,
  AppLabelWrapper,
  AppValueWrapper,
} from 'components/AppAccount/AppAccount.style'
import { AppCheckBox } from 'components/Common/AppFormFields/AppCheckBox/AppCheckBox.component'

interface IAppBusinessProfileCheckBoxField {
  label?: string
  checkboxLabel: string
  checkboxValue: boolean
  LabelIcon?: React.ComponentType
}
export const AppBusinessProfileCheckBoxField: AppFC<IAppBusinessProfileCheckBoxField> = ({
  label,
  checkboxLabel,
  checkboxValue,
  LabelIcon,
}) => {
  return (
    <AppFieldWrapper>
      <AppLabelWrapper>
        {LabelIcon && <LabelIcon />}
        {label && label}
      </AppLabelWrapper>
      <AppValueWrapper>
        <AppCheckBox label={checkboxLabel} value={checkboxValue} disabled={true}></AppCheckBox>
      </AppValueWrapper>
    </AppFieldWrapper>
  )
}
